<template>
  <section id="daftar-efisiensi-anggaran">
    <b-row>
      <b-col
        cols="12"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            class="mt-2"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>
        <b-card>
          <!-- search input -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_data_instansi"
                  v-model="master_data_instansi"
                  required
                  name="master_data_instansi"
                  :options="referenceData.ref_instansi"
                  placeholder="Daftar Instansi"
                  label="name"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length,
              mode: 'pages',
              setCurrentPage:selectPage,
              perPageDropdown: [15, 30, 60, 100],
              dropdownAllowAll: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <div v-if="props.column.field == 'filename'">
                <span class="font-weight-bolder">
                  {{ props.row.filename }}
                </span>
                <b-link
                  v-b-tooltip.hover
                  :href="getDoc(props.row.document_uri)"
                  class="btn btn-sm btn-success ml-50 mb-25"
                  target="_blank"
                  title="Download Matrix Efisiensi"
                >
                  <feather-icon
                    icon="DownloadCloudIcon"
                    size="12"
                  />
                </b-link>
              </div>
              <span v-else-if="props.column.field === 'created_on'">
                {{ props.row.created_on | formatDate }}
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
          <p class="mx-1 my-0">
            Total <b>{{ totalRecords }}</b> Entries
          </p>
        </b-card>

      </b-col>
      <!-- post -->

    </b-row>

    <b-modal
      id="modal-rekomendasi"
      ref="modal-rekomendasi"
      hide-footer
      centered
      size="lg"
      :title="modalTitle"
    >
      <summary-rekomendasi
        :rekap-lanjut="listRekomendasi.continue"
        :rekap-tidak="listRekomendasi.postpone"
      />
    </b-modal>

  </section>

</template>

<script>
import store from '@/store'
import moment from 'moment-business-time'
import vSelect from 'vue-select'
import {
  BAlert, BRow, BButton, BModal, BCol, BCard, BFormGroup, BLink,
  BFormInput, VBTooltip, VBModal, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

import SummaryRekomendasi from '../rekomendasi/SummaryRekomendasi.vue'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BLink,
    BButton,
    BCard,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
    BAlert,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    SummaryRekomendasi,
    vSelect,
  },
  setup() {
    const yearBudget = localStorage.getItem('tahunAnggaran')
    const columns = [
      {
        thClass: 'text-center',
        label: 'K/L',
        field: 'prefix',
        width: '175px',
        filterOptions: {
          enabled: true,
          placeholder: 'Nama Instansi',
        },
      },
      {
        thClass: 'text-center',
        label: 'Dokumen',
        field: 'filename',
        sortable: false,
      },
      {
        thClass: 'text-center',
        label: 'Diunggah',
        field: 'created_on',
      },
    ]

    const serverParams = {
      tahun_anggaran: yearBudget,
      sort_by: [
        {
          column: 'created_on',
          asc_desc: 'ASC',
        },
      ],
      start: 0,
      length: 30,
    }

    return {
      yearBudget,
      columns,
      serverParams,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  filters: {
    momentWait(date) {
      const now = new Date()
      const today = moment(now).utc()
      const diff = today.workingDiff(date, 'days')
      return `${diff} Hari`
    },
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 70) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      alertText: '',
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
      },
      modalTitle: '',
      isLoading: false,
      totalRecords: 0,
      selectPage: 1,
      rows: [],
      searchTerm: '',
      errorStat: false,
      errorMsg: '',
      listRekomendasi: [],
      userRekoLanjut: [],
      userRekoTidak: [],
      master_data_instansi: '',
      master_jenis_belanja_id: '',
      master_jenis_pengadaan_id: '',
      master_sumber_dana_id: '',
    }
  },
  created() {
    this.setupTanggal()
  },
  mounted() {
    if (localStorage.getItem('master_instansi_id')) {
      this.master_data_instansi = JSON.parse(localStorage.getItem('master_instansi_id'))
    }
    if (localStorage.getItem('searchTerm')) {
      this.searchTerm = localStorage.getItem('searchTerm')
    }
    this.getReference()
    const { page, currentPage, currentPerPage } = store.state.app
    if (page === 'efisiensi' && currentPage && currentPerPage) {
      this.selectPage = currentPage
      this.serverParams.length = currentPerPage
      this.updateParams({ start: (currentPage - 1) * this.serverParams.length })
    }
    this.doFilter()
    store.commit('app/SET_PAGE', 'efisiensi')
  },
  methods: {
    setupTanggal() {
      let holidays = []
      if (localStorage.getItem('holidays')) {
        holidays = JSON.parse(localStorage.getItem('holidays'))
      } else {
        this.$http.get('/tanggalan/holidays')
          .then(res => {
            const liburAll = res.data.data
            const libur = []

            Object.keys(liburAll).map(data => {
              libur.push(data)
              return data
            })
            holidays = libur
            localStorage.setItem('holidays', JSON.stringify(libur))
          })
      }
      moment.updateLocale('en', {
        holidays,
      })
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    getReference() {
      this.$http.get('/recommendation/list-references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data.references
          }
        })
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    globalFilter() {
      const params = []
      if (this.master_data_instansi) {
        params.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      return params
    },
    doFilter() {
      const params = this.globalFilter()
      if (this.searchTerm !== '') {
        this.updateParams({ keyword: this.searchTerm })
        localStorage.setItem('searchTerm', this.searchTerm)
      } else {
        this.updateParams({ keyword: '' })
        localStorage.removeItem('searchTerm')
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      store.commit('app/CURRENT_PAGE', params.currentPage)
      this.selectPage = store.state.app.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      store.commit('app/CURRENT_PER_PAGE', params.currentPerPage)
      this.updateParams({ length: params.currentPerPage, start: (this.selectPage - 1) * params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({ start: 0 })
      params.map(param => {
        let columName = param.field
        if (param.field === 'instansi_prefix') {
          columName = 'prefix'
        }
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: columName,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams({ start: 0 })
      const filterz = this.globalFilter()
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry

        let columName = key
        if (key === 'instansi_prefix') {
          columName = 'prefix'
        }
        filterz.push({
          column: columName,
          value: valuez,
        })
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/efisiensi-anggaran/list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          if (res.data.status === 'success') {
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.totalRecords = 0
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
