var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"daftar-efisiensi-anggaran"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.errorStat)?[_c('b-alert',{staticClass:"mt-2",attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error Found ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])])]:_vm._e(),_c('b-card',[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('v-select',{attrs:{"id":"vi-master_data_instansi","required":"","name":"master_data_instansi","options":_vm.referenceData.ref_instansi,"placeholder":"Daftar Instansi","label":"name"},on:{"input":function($event){return _vm.doFilter()}},model:{value:(_vm.master_data_instansi),callback:function ($$v) {_vm.master_data_instansi=$$v},expression:"master_data_instansi"}})],1)],1),_c('b-col',[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Search","type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.tableSearch.apply(null, arguments)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-input-group-append',[(_vm.searchTerm)?_c('b-button',{staticClass:"p-50",attrs:{"variant":"outline-secondary"},on:{"click":_vm.searchReset}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1):_vm._e()],1),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.tableSearch}},[_c('feather-icon',{attrs:{"icon":"SearchIcon","size":"12"}})],1)],1)],1)],1)],1),_c('vue-good-table',{attrs:{"mode":"remote","total-rows":_vm.totalRecords,"is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
            enabled: true,
            perPage:_vm.serverParams.length,
            mode: 'pages',
            setCurrentPage:_vm.selectPage,
            perPageDropdown: [15, 30, 60, 100],
            dropdownAllowAll: false,
          }},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'filename')?_c('div',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(props.row.filename)+" ")]),_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-sm btn-success ml-50 mb-25",attrs:{"href":_vm.getDoc(props.row.document_uri),"target":"_blank","title":"Download Matrix Efisiensi"}},[_c('feather-icon',{attrs:{"icon":"DownloadCloudIcon","size":"12"}})],1)],1):(props.column.field === 'created_on')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.created_on))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('p',{staticClass:"mx-1 my-0"},[_vm._v(" Total "),_c('b',[_vm._v(_vm._s(_vm.totalRecords))]),_vm._v(" Entries ")])],1)],2)],1),_c('b-modal',{ref:"modal-rekomendasi",attrs:{"id":"modal-rekomendasi","hide-footer":"","centered":"","size":"lg","title":_vm.modalTitle}},[_c('summary-rekomendasi',{attrs:{"rekap-lanjut":_vm.listRekomendasi.continue,"rekap-tidak":_vm.listRekomendasi.postpone}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }